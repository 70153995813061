import React, { useState, useRef, useContext, useEffect } from 'react'
import { ReactThemeContext } from '../../../reactTheme'
import type { ArcContainer } from '@arc-web/components'
import { useTranslation } from 'react-i18next'
import { IPublicClientApplication } from '@azure/msal-browser'
import { Grid, Paper, withStyles, WithStyles, createStyles, Theme, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { msal } from '../../auth'
import ArupLogo2 from '../../../images/arupLogo2.svg'

interface IProps {}

interface MyCustomCSS extends CSSStyleDeclaration {
  '--btn-background': string
  '--btn-color': string
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      textAlign: 'center'
    },
    paper: {
      width: '312px',
      height: '284px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    logo: {
      marginTop: '30px',
      width: '91px',
      height: '70px'
    },
    intro: {
      letterSpacing: '0.23px',
      color: theme.palette.text.primary
    },
    grow: {
      flexGrow: 1
    },
    loginButton: {
      width: '248px',
      height: '32px',
      borderRadius: '4px',
      marginBottom: '30px'
    },

    loginLoading: {
      marginBottom: '30px'
    }
  })

type ClassKey = 'root' | 'paper' | 'logo' | 'intro' | 'grow' | 'loginButton' | 'loginLoading'

type PropsType = IProps & WithStyles<ClassKey>

const SignIn: React.FC<PropsType> = (props) => {
  useEffect(() => {
    import('@arc-web/components/dist/components/container/arc-container.js')
    import('@arc-web/components/dist/components/navbar/arc-navbar.js')
    import('@arc-web/components/dist/components/icon-button/arc-icon-button.js')
    import('@arc-web/components/dist/components/icon/arc-icon.js')
  }, [])
  const { i18n } = useTranslation()
  const [signInStart, setSignInStart] = useState(false)
  const { classes } = props
  const { toggleDarkMode } = useContext(ReactThemeContext)

  /* Add an eventListener to the arc-container component */
  const containerRef = useRef<ArcContainer>(null)
  useEffect(() => {
    /* Wrap within an if statement to ensure that the element is actually available */
    if (containerRef && containerRef.current) {
      containerRef.current.addEventListener('arc-accessibility-change', toggleDarkMode)
      return () => containerRef.current?.removeEventListener('arc-accessibility-change', toggleDarkMode)
    }
  }, [toggleDarkMode])

  const signInClickHandler = (instance: IPublicClientApplication) => {
    setSignInStart(true)
    instance.loginRedirect()
  }

  return (
    // @ts-ignore
    <arc-container ref={containerRef} fullscreen>
      <arc-navbar logo={'/assets/arc-red.svg'} slot={'nav'}></arc-navbar>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid key={'1'} item>
              <Paper className={classes.paper} elevation={3}>
                <img className={classes.logo} src={ArupLogo2} alt="Arup" />
                <Typography className={classes.intro}>{i18n.t('welcome')}</Typography>
                <div className={classes.grow} />
                {!signInStart && (
                  <div className={classes.loginButton}>
                    <arc-button color={'primary'} onClick={() => signInClickHandler(msal)}>
                      {i18n.t('signin')}
                    </arc-button>
                  </div>
                )}
                {signInStart && (
                  <CircularProgress className={classes.loginLoading} color="secondary" size={64} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </arc-container>
  )
}

export default withStyles(styles)(SignIn)
