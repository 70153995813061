import React, { useState } from 'react'
import { withStyles, WithStyles, createStyles, Theme, Avatar, Typography } from '@material-ui/core'
import LanguageDialog from './LanguageDialog'
import { useTranslation } from 'react-i18next'
import { getUserInfo, signOut } from '../../../auth'
import { ExitToApp, Language, TrackChanges, Explore } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import config from '../../../../config'
import '@arc-web/components/dist/components/navbar/arc-navbar.js'
import '@arc-web/components/dist/components/dropdown/arc-dropdown.js'
import '@arc-web/components/dist/components/button/arc-button.js'
import '@arc-web/components/dist/components/icon-button/arc-icon-button.js'
import '@arc-web/components/dist/components/icon/arc-icon.js'
import '@arc-web/components/dist/components/menu/arc-menu.js'
import '@arc-web/components/dist/components/menu-item/arc-menu-item.js'

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      width: 30,
      height: 30,
      cursor: ' pointer',
      margin: 3
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      '&:hover': {
        color: '#E61E28'
      }
    },
    menuItem: {
      display: 'inline-flex'
    }
  })

type ClassKey = 'root' | 'avatar' | 'profileMenuIcon' | 'menuItem'
type PropsType = IProps & WithStyles<ClassKey>

const UserMenu: React.FC<PropsType> = (props) => {
  const isProd = config.project.name === 'prod'
  const history = useHistory()
  const { i18n } = useTranslation()
  const { classes } = props
  const [lngOpen, setLngOpen] = useState<boolean>(false)
  const doSignOut = () => {
    signOut()
  }
  const loadSensors = () => {
    history.push('/sensors')
  }
  const loadBridges = () => {
    history.push('/bridges')
  }

  return (
    <div slot={'user'} className={classes.root}>
      <arc-dropdown hoist>
        <Avatar
          slot={'trigger'}
          alt={getUserInfo().name}
          src={getUserInfo().avatar}
          classes={{
            root: classes.avatar
          }}
        />

        <arc-menu>
          <arc-menu-item>
            <Typography variant="h5">{getUserInfo().name}</Typography>
            <Typography component="a" href={'mailto:' + getUserInfo().email} color="primary">
              {getUserInfo().email}
            </Typography>
          </arc-menu-item>
          <arc-menu-item onClick={() => loadBridges()}>
            <div className={classes.menuItem}>
              <Explore className={classes.profileMenuIcon} />
              {i18n.t('bridges')}
            </div>
          </arc-menu-item>
          {!isProd && (
            <arc-menu-item onClick={() => loadSensors()}>
              <div className={classes.menuItem}>
                <TrackChanges className={classes.profileMenuIcon} />
                {i18n.t('sensors')}
              </div>
            </arc-menu-item>
          )}
          <arc-menu-item onClick={() => setLngOpen(true)}>
            <div className={classes.menuItem}>
              <Language className={classes.profileMenuIcon} />
              {i18n.t('language')}
            </div>
          </arc-menu-item>
          <arc-menu-item onClick={() => doSignOut()}>
            <div className={classes.menuItem}>
              <ExitToApp className={classes.profileMenuIcon} />
              {i18n.t('signout')}
            </div>
          </arc-menu-item>
        </arc-menu>
      </arc-dropdown>

      <LanguageDialog open={lngOpen} setOpen={setLngOpen} />
    </div>
  )
}

export default withStyles(styles)(UserMenu)
