import React from 'react'
import UserMenu from './UserMenu/UserMenu'
import config from '../../../config'
import '@arc-web/components/dist/components/navbar/arc-navbar.js'

const AppBar: React.FC = () => {
  return (
    <arc-navbar slot={'nav'} logo={'/assets/arc-red.svg'} home={config.activeDirectory.auth.redirectUri}>
      <UserMenu />
    </arc-navbar>
  )
}

export default AppBar
