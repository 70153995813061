import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Grid,
  CardActionArea,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import { Sensor, Project } from '../../../shared/interfaces'
import { getSensorData, getSensors } from '../../../shared/api'
import { createSensors } from '../../../shared/helper'
import Loading from '../Loading/Loading'
import Error from '../Error/Error'
import { SENSOR_SUPPLIERS, SENSOR_TAGS } from '../../../shared/constants'
import { getProjects } from '../../../shared/api'
import { createProjects } from '../../../shared/helper'

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#E4E4E5'
    },
    content: {
      padding: '0'
    },
    group: {
      color: theme.palette.primary.main,
      letterSpacing: '0px',
      fontWeight: 'bolder',
      paddingBottom: '10px'
    },
    name: {
      color: theme.palette.secondary.main,
      letterSpacing: '0px',
      paddingBottom: '10px'
    },
    card: {
      boxShadow: '4px 4px 6px #00000029'
    },
    cardDiv: {
      marginLeft: '30px',
      marginRight: '30px',
      marginTop: '30px'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    searchButton: {
      marginLeft: '15px',
      marginTop: '20px'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 220
    },
    sensorDiv: {
      marginLeft: '30px',
      marginRight: '30px'
    },
    sensorData: {
      padding: '0 !important',
      display: 'flex',
      justifyContent: 'space-between'
    },
    menuItem: {
      color: 'rgb(var(--arc-grey-100))'
    }
  })

type ClassKey =
  | 'root'
  | 'group'
  | 'name'
  | 'content'
  | 'card'
  | 'cardDiv'
  | 'formControl'
  | 'searchButton'
  | 'textField'
  | 'sensorDiv'
  | 'sensorData'
  | 'menuItem'
type PropsType = WithStyles<ClassKey>

const Sensors: React.FC<PropsType> = (props: PropsType) => {
  const transFormDateTime = (minusHour: number = 0) => {
    const date = new Date()
    const year = date.getFullYear()
    const monthWithOffset = date.getUTCMonth() + 1
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset
    const day = date.getUTCDate().toString().length < 2 ? `0${date.getUTCDate()}` : date.getUTCDate()
    const correctedHours = date.getUTCHours().toString().length - minusHour
    const hours = correctedHours < 10 ? `0${correctedHours}` : correctedHours
    const minutes =
      date.getUTCMinutes().toString().length < 2 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes()
    const value = year + '-' + month + '-' + day + 'T' + hours + ':' + minutes
    return value
  }

  const { i18n } = useTranslation()
  const [expanded, setExpanded] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [sensors, setSensors] = useState<Sensor[]>([])
  const [sensorsData, setSensorsData] = useState({})

  const [project, setProject] = useState('Galecopper Bypass')
  const [supplier, setSupplier] = useState('')
  const [tag, setTag] = useState('')
  const [startTime, setStartTime] = useState(transFormDateTime(1))
  const [endTime, setEndTime] = useState(transFormDateTime())
  const [projects, setProjects] = useState<Project[]>([])
  // TODO add timebucket and envelope for the sensor data?

  const handleExpandClick = async (event, index) => {
    const newExpanded = { ...expanded }
    newExpanded[index] = !newExpanded[index]
    setExpanded(newExpanded)

    const newSensorsData = { ...sensorsData }
    if (newExpanded[index]) {
      // we opened the card, load the sensor data
      // TODO: The time bucket has a placeholder value of "1week"
      await getSensorData(index, startTime, endTime, '1week')
        .then((result) => (newSensorsData[index] = result))
        .catch((error) => (newSensorsData[index] = error))
    } else {
      // we close the card, remove the sensor data so we can load it again when it gets expanded
      newSensorsData[index] = null
    }

    setSensorsData(newSensorsData)
  }

  useEffect(() => {
    doGetSensors()
    getProjects()
      .then((result) => setProjects(createProjects(result)))
      .catch((error) => setIsError(true))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    doGetSensors()
    // eslint-disable-next-line
  }, [project, supplier, tag])

  const doGetSensors = () => {
    setIsLoading(true)
    setIsError(false)
    getSensors(project, supplier, tag)
      .then((result) => setSensors(createSensors(result)))
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }

  const clear = () => {
    setProject('')
    setSupplier('')
    setTag('')
    setStartTime('')
    setEndTime('')
  }

  const handleProjectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProject(event.target.value as string)
  }
  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSupplier(event.target.value as string)
  }
  const handleTagChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTag(event.target.value as string)
  }
  const handleStartTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStartTime(event.target.value as string)
  }
  const handleEndTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEndTime(event.target.value as string)
  }

  return (
    <>
      {isLoading && <Loading spinnerSize={96} text={i18n.t('loadingSensors') + '...'} />}
      {!isLoading && isError && <Error text={i18n.t('errorLoadingSensors') + '.'} />}
      {!isLoading && !isError && (
        <div style={{ padding: 30 }}>
          <div>
            <FormControl className={props.classes.formControl}>
              <InputLabel htmlFor="grouped-select">Project</InputLabel>
              <Select value={project} id="grouped-select" onChange={handleProjectChange}>
                <MenuItem value="" className={props.classes.menuItem}></MenuItem>
                {projects.map(
                  (project) =>
                    project.sensor && (
                      <MenuItem key={project.name} value={project.name} className={props.classes.menuItem}>
                        {project.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            <FormControl className={props.classes.formControl}>
              <InputLabel htmlFor="grouped-select">Supplier</InputLabel>
              <Select value={supplier} id="grouped-select" onChange={handleSupplierChange}>
                <MenuItem value="" className={props.classes.menuItem}></MenuItem>
                {SENSOR_SUPPLIERS.map((supplier) => (
                  <MenuItem key={supplier} value={supplier} className={props.classes.menuItem}>
                    {supplier}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={props.classes.formControl}>
              <InputLabel htmlFor="grouped-select">Tag</InputLabel>
              <Select value={tag} id="grouped-select" onChange={handleTagChange}>
                <MenuItem value="" className={props.classes.menuItem}></MenuItem>
                {SENSOR_TAGS.map((tag) => (
                  <MenuItem key={tag} value={tag} className={props.classes.menuItem}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={props.classes.formControl}>
              <TextField
                id="datetime-local"
                label="Start Time"
                type="datetime-local"
                value={startTime}
                onChange={handleStartTimeChange}
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
            <FormControl className={props.classes.formControl}>
              <TextField
                id="datetime-local"
                label="End Time"
                type="datetime-local"
                value={endTime}
                onChange={handleEndTimeChange}
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
            <Button className={props.classes.searchButton} variant="outlined" onClick={clear}>
              Clear
            </Button>
            <Button
              className={props.classes.searchButton}
              variant="outlined"
              color="secondary"
              onClick={doGetSensors}
            >
              Search
            </Button>
          </div>
          <Grid container spacing={5}>
            {sensors.map((sensor) => (
              <Grid item xs={6} sm={6} md={4} lg={3} xl={2} key={sensor.id} style={{ minWidth: '336px' }}>
                <Card className={props.classes.card} onClick={(event) => handleExpandClick(event, sensor.id)}>
                  <CardActionArea>
                    <div className={props.classes.cardDiv}>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.group}>{sensor.group}</Typography>
                      </CardContent>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.name}>
                          <b>Name</b>: {sensor.name}
                        </Typography>
                      </CardContent>
                      {sensor.location && (
                        <CardContent className={props.classes.content}>
                          <Typography className={props.classes.name}>
                            <b>Location</b>: {sensor.location}
                          </Typography>
                        </CardContent>
                      )}
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.name}>
                          <b>Supplier</b>: {sensor.supplier}
                        </Typography>
                      </CardContent>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.name}>
                          <b>Coordinates</b>: {sensor.x},{sensor.y},{sensor.z}
                        </Typography>
                      </CardContent>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.name}>
                          <b>State</b>: {sensor.state}
                        </Typography>
                      </CardContent>
                    </div>
                  </CardActionArea>
                  <Collapse in={expanded[sensor.id]} timeout="auto" unmountOnExit>
                    <div
                      onClick={(event) => {
                        // we don't want to trigger the click of the card when selecting something
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                    >
                      {!sensorsData[sensor.id] && (
                        <div style={{ display: 'flex' }}>
                          <CircularProgress size={24} color="secondary" />
                          <div style={{ paddingLeft: '10px' }}>{i18n.t('loadingSensor') + '...'}</div>
                        </div>
                      )}
                      {sensorsData[sensor.id] &&
                        Array.isArray(sensorsData[sensor.id]) &&
                        sensorsData[sensor.id].map((data) => (
                          <div className={props.classes.sensorDiv} key={data[0]}>
                            <CardContent className={props.classes.sensorData}>
                              <Typography className={props.classes.name}>
                                <b>Time</b>: {new Date(data[0]).toLocaleString()}
                              </Typography>
                              <Typography className={props.classes.name}>
                                <b>Value</b>: {data[1]}
                              </Typography>
                            </CardContent>
                          </div>
                        ))}
                      {sensorsData[sensor.id] && !Array.isArray(sensorsData[sensor.id]) && (
                        <div className={props.classes.sensorDiv}>
                          <CardContent className={props.classes.sensorData}>
                            <Typography className={props.classes.name}>
                              <b>Error</b>: {sensorsData[sensor.id]}
                            </Typography>
                          </CardContent>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(Sensors)
