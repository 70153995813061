import React, { useState } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'
import { Project } from '../../../shared/interfaces'
import LoadingAnimation from '../Loading/Loading'
import { useTranslation } from 'react-i18next'

import Error from '../Error/Error'

import Viewer from './Viewer/Viewer'

interface IProps {
  project: Project | undefined
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const Shapediver: React.FC<PropsType> = (props) => {
  const { project } = props
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingBridge, setIsLoadingBridge] = useState(true)
  const { i18n } = useTranslation()

  return (
    <>
      {/* {isError && <Error text={errorMessage}></Error>}
      {!isError && ( */}
      <div
        className={props.classes.root}
        style={{
          visibility: !isLoadingBridge ? 'visible' : 'hidden',
          height: !isLoadingBridge ? '92vh' : '0vh'
        }}
      >
        <h3>{project?.name}</h3>
        <Viewer
          ticket={project?.stream_id!}
          modelViewUrl="eu-central-1"
          setIsLoadingBridge={setIsLoadingBridge}
        />
      </div>
      <div>{isLoadingBridge && <LoadingAnimation text={i18n.t('loadingBridge')} spinnerSize={96} />}</div>
      {/* )} */}
    </>
  )
}

export default withStyles(styles)(Shapediver)
