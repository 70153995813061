import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { Project } from '../../../shared/interfaces'
import { createProjects } from '../../../shared/helper'
import Loading from '../Loading/Loading'
import Error from '../Error/Error'
import { getProjects } from '../../../shared/api'

interface IProps {
  onProjectOpen: any
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    content: {
      padding: '0'
    },
    location: {
      color: theme.palette.primary.main,
      letterSpacing: '0px',
      fontWeight: 'bolder',
      paddingBottom: '4px'
    },
    name: {
      color: theme.palette.secondary.main,
      letterSpacing: '0px',
      paddingBottom: '10px'
    },
    cardDiv: {
      margin: '30px'
    }
  })

type ClassKey = 'location' | 'name' | 'content' | 'cardDiv'
type PropsType = IProps & WithStyles<ClassKey>

const Projects: React.FC<PropsType> = (props: PropsType) => {
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [projects, setProjects] = useState<Project[]>([])

  const hasDisabledStyle = (project: Project): React.CSSProperties => {
    const name = project.name.toLocaleLowerCase()
    const isProjectDisabled = !name.includes('galecopper') && !name.includes('brienenoord')
    return {
      opacity: isProjectDisabled ? '0.50' : '1',
      pointerEvents: isProjectDisabled ? 'none' : 'auto'
    }
  }

  useEffect(() => {
    getProjects()
      .then((result) => setProjects(createProjects(result)))
      .catch((error) => setIsError(true))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      {isLoading && <Loading spinnerSize={96} text={i18n.t('loadingBridges') + '...'} />}
      {!isLoading && isError && <Error text={i18n.t('errorLoadingBridges') + '.'} />}
      {!isLoading && !isError && (
        <Grid container spacing={5} style={{ margin: 0, width: '100%' }}>
          {projects.map((project) => (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={2} key={project.name}>
              <div style={hasDisabledStyle(project)}>
                <Card variant="outlined" onClick={() => props.onProjectOpen(project)}>
                  <CardActionArea>
                    <div className={props.classes.cardDiv}>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.location}>{project.location}</Typography>
                      </CardContent>
                      <CardContent className={props.classes.content}>
                        <Typography className={props.classes.name}>{project.name}</Typography>
                      </CardContent>
                      <CardMedia component="img" height="160" image={project.image} />
                    </div>
                  </CardActionArea>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}

export default withStyles(styles)(Projects)
