import React, { useState, createContext } from 'react'
import { ThemeProvider } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'

export const ReactThemeContext = createContext({
  darkMode: false,
  toggleDarkMode: (e: any) => {}
})

interface IProps {}

export const BridgeThemeProvider: React.FC<IProps> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const toggleDarkMode = (e: CustomEvent) => {
    const { preferences } = e.detail
    const { theme } = preferences
    setDarkMode(() => theme === 'dark')
  }

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: 'rgb(var(--arc-color-primary))'
      },
      secondary: {
        main: 'rgb(var(--arc-color-secondary))'
      },
      error: {
        main: 'rgb(var(--arc-color-error))'
      },
      warning: {
        main: 'rgb(var(--arc-color-warning))'
      },
      info: {
        main: 'rgb(var(--arc-color-info))'
      },
      success: {
        main: 'rgb(var(--arc-color-success))'
      },
      background: {
        default: 'rgb(var(--arc-background-color))',
        paper: 'rgb(var(--arc-container-color))'
      }
    }
  })

  return (
    <ReactThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ReactThemeContext.Provider>
  )
}
