import { Project, Sensor } from '../shared/interfaces'

import NoImageAvailable from '../images/no_image_available.png'
import ZijkanaalImage from '../images/Zijkanaal.png'
import GalecopperImage from '../images/GalecopperBridge.jpg'
import Brienenoord from '../images/Brienenoord.jpg'
import { SensorState } from './constants'

export function createProjects(result: any[]): Project[] {
  const projects: Project[] = []
  result?.forEach((project) => {
    projects.push(createProject(project))
  })
  return projects
}

function createProject(project: any): Project {
  const { id, name, active, job_number, description, stream_id, location, viewtype, sensor } = project
  return {
    id: id,
    name: name,
    status: active ? 'online' : 'offline',
    job_number: job_number,
    image: getProjectImage(name),
    description: description,
    stream_id: stream_id,
    location: location,
    type: viewtype,
    sensor: sensor
  }
}

// TODO This is a temporary solution until the backend provides the image
function getProjectImage(projectName: string): string {
  const name = projectName.toLocaleLowerCase()
  if (name.includes('brienenoord')) {
    return Brienenoord
  }
  if (name.includes('galecopper')) {
    return GalecopperImage
  }
  if (name.includes('zijkanaal')) {
    return ZijkanaalImage
  }
  return NoImageAvailable
}

// Sensor result structure:
// 0 - Sensor ID
// 1 - Sensor tag name
// 2 - Sensor location description
// 3 - Sensor supplier name
// 4 - Sensor group name
// 5 - Sensor x-coordinate
// 6 - Sensor y-coordinate
// 7 - Sensor z-coordinate
// 8 - Sensor state (1 is online,  2 is offline, 3 is alarm (has hit the sensor limit))
export function createSensors(result: any[]): Sensor[] {
  const sensors: Sensor[] = []
  result?.forEach((sensor) => {
    sensors.push(createSensor(sensor))
  })
  return sensors
}

function createSensor(sensor: any): Sensor {
  return {
    id: sensor[0],
    name: sensor[1],
    location: sensor[2],
    supplier: sensor[3],
    group: sensor[4],
    x: sensor[5],
    y: sensor[6],
    z: sensor[7],
    state: SensorState[sensor[8]]
  }
}
