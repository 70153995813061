const showMenusVisSelected = (visSelected) => {
  let menuItemsShown: string[] = []
  if (visSelected === 'Load Case') {
    menuItemsShown = [
      'Nodes',
      'Elements',
      'Results',
      'Contour',
      'Diagram Scale',
      'Case',
      'Element x-axis',
      'Legend Type'
    ]
  } else if (visSelected === 'Combination') {
    menuItemsShown = [
      'Nodes',
      'Elements',
      'Results',
      'Contour',
      'Diagram Scale',
      'Optimized design action filter',
      'Combination Formula',
      'Element x-axis',
      'Legend Type'
    ]
  } else if (visSelected === 'Other') {
    menuItemsShown = [
      'Nodes',
      'Elements',
      'Results',
      'Contour',
      'Diagram Scale',
      'Element x-axis',
      'Legend Type',
      'Combination Formula'
    ]
  }
  return menuItemsShown
}

const showMenusLegendSelected = (param, menuItemsShown) => {
  if (param.choices![param.value!] === 'Auto') {
    menuItemsShown.push('Legend Fields')
  } else if (param.choices![param.value!] === 'Manual') {
    menuItemsShown.push('Manual Legend Max', 'Manual Legend Min', 'Manual Legend Step-size')
  }
  return menuItemsShown
}

export { showMenusVisSelected, showMenusLegendSelected }
