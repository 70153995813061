import React, { useState } from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Projects from '../Projects/Projects'
import UnityInterface from '../Unity/Unity'
import Shapediver from '../Shapediver/Shapediver'
import { Project } from '../../../shared/interfaces'
import { PROJECT_TYPES } from '../../../shared/constants'

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }
  })

type ClassKey = 'root'
type PropsType = WithStyles<ClassKey>

const Main: React.FC<PropsType> = (props: PropsType) => {
  const [project, setProject] = useState<Project>()

  // TODO improve this for further components
  const [loadUnity, setLoadUnity] = useState(false)
  const [loadShapediver, setLoadShapediver] = useState(false)

  const onProjectOpen = (project: Project) => {
    setProject(project)
    console.log(project)
    switch (project.type) {
      case PROJECT_TYPES.UNITY:
        setLoadUnity(true)
        break
      case PROJECT_TYPES.SHAPEDIVER:
        setLoadShapediver(true)
        break
      default:
        console.log('no component to load')
    }
  }

  return (
    <>
      {!loadUnity && !loadShapediver && <Projects onProjectOpen={onProjectOpen} />}
      {loadUnity && <UnityInterface project={project} />}
      {loadShapediver && <Shapediver project={project} />}
    </>
  )
}

export default withStyles(styles)(Main)
