import React from 'react'
import ReactDOM from 'react-dom'
import { CssBaseline } from '@material-ui/core'
import { Router, Route, Switch } from 'react-router'
import { createBrowserHistory } from 'history'
import { I18nextProvider } from 'react-i18next'
import { MsalProvider } from '@azure/msal-react'
import { msal } from './common/auth'
import { Authenticated } from './common/components/Authentication/Authenticated'
import { Unauthenticated } from './common/components/Authentication/Unauthenticated'
import i18n from './i18n'
import * as serviceWorker from './serviceWorker'
import { BridgeThemeProvider } from './reactTheme'
import Frame from './common/components/Frame/Frame'
import '@arc-web/components/dist/themes/index.css'
import '@arc-web/components/dist/themes/light.css'
import '@arc-web/components/dist/themes/dark.css'
import './index.css'

const App = () => {
  const browserHistory = createBrowserHistory()

  return (
    <MsalProvider instance={msal}>
      <I18nextProvider i18n={i18n}>
        <BridgeThemeProvider>
          <CssBaseline />
          <Authenticated>
            <Router history={browserHistory}>
              <Switch>
                <Route component={Frame} />
              </Switch>
            </Router>
          </Authenticated>
          <Unauthenticated></Unauthenticated>
        </BridgeThemeProvider>
      </I18nextProvider>
    </MsalProvider>
  )
}

export default App

ReactDOM.render(<App />, document.getElementById('root'))

// Re-render the complete React component tree
// This should only be used to reflect language preference changes
export function updateComponentTree() {
  // TODO Improve this to only rerender the components, not reload the entire page
  // Currently it is needed to keep the Authentication working
  window.location.reload()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
