import React, { useEffect, useRef, useContext } from 'react'
import { WithStyles } from '@material-ui/core'
import { Route, Switch } from 'react-router'
import { ReactThemeContext } from '../../../reactTheme'
import type { ArcContainer } from '@arc-web/components'
import '@arc-web/components/dist/components/container/arc-container.js'
import AppBar from './AppBar'
import Main from '../Main/Main'
import Sensors from '../Sensors/Sensors'
import NotFound from '../NotFound/NotFound'

type ClassKey = 'root'
type PropsType = WithStyles<ClassKey>

const Frame: React.FC<PropsType> = (props: PropsType) => {
  const { toggleDarkMode } = useContext(ReactThemeContext)

  /* Add an eventListener to the arc-container component */
  const containerRef = useRef<ArcContainer>(null)
  useEffect(() => {
    /* Wrap within an if statement to ensure that the element is actually available */
    if (containerRef && containerRef.current) {
      containerRef.current.addEventListener('arc-accessibility-change', toggleDarkMode)
      return () => containerRef.current?.removeEventListener('arc-accessibility-change', toggleDarkMode)
    }
  }, [toggleDarkMode])

  return (
    // @ts-ignore
    <arc-container ref={containerRef} fullscreen>
      <AppBar />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/bridges" component={Main} />
        <Route path="/sensors" component={Sensors} />
        <Route component={NotFound} />
      </Switch>
    </arc-container>
  )
}

export default Frame
