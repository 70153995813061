import React from 'react'
import { withStyles, WithStyles, createStyles, Theme, CircularProgress } from '@material-ui/core'
import '@arc-web/components/dist/components/spinner/arc-spinner.js'

interface IProps {
  text: string
  spinnerSize: number
  height?: string
  width?: string
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 'var(--arc-spacing-medium)'
    }
  })

type ClassKey = 'root'

type PropsType = IProps & WithStyles<ClassKey>

const Loading: React.FC<PropsType> = (props: PropsType) => {
  return (
    <div className={props.classes.root}>
      {/*@ts-ignore*/}
      <arc-spinner style={{ fontSize: `${props.spinnerSize}px` }}>
        <></>
      </arc-spinner>
      <span>{props.text}</span>
    </div>
  )
}

export default withStyles(styles)(Loading)
